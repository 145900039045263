import React from "react";
import { TextField, useTheme } from "@mui/material";

export default function LoginInput({
  name,
  label = "default label",
  formik,
  required = false,
  disabled = false,
  containerStyle = {},
  inputProps = {},
  ...rest
}) {
  const theme = useTheme();

  return (
    <TextField
      required={required}
      autoComplete="off"
      id={name}
      name={name}
      disabled={disabled}
      placeholder={`Enter ${label}`}
      placeholderTextColor
      fullWidth
      value={formik.values[name] || ""}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      sx={{
        mt: 4,
        borderRadius: "25px",
        maxWidth: "350px",
      }}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        multiple: true,
        autoComplete: "new-password",
        form: {
          autoComplete: "off",
        },
        sx: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "green",
            },
          },
        },
        style: {
          borderRadius: "25px",
          fontSize: "18px",
          height: "50px",
          color: "white",
        },
        ...inputProps,
      }}
      {...rest}
    />
  );
}
