import React, { useContext } from "react";

import DashBoardPageHeaderPage from "../components/DashBoard/DashBoardHeaderPage";
import DashBoarddShipmentPage from "../components/DashBoard/DashBoardShipmentPage";
import DashBoardItems from "../components/DashBoard/DashBoardItems";
import { Box } from "@mui/material";
import BundleSummary from "../components/DashBoard/BundleSummary";
import CurrentDateDetails from "../components/DashBoard/CurrentDateDetails";
import Outbounds from "../components/DashBoard/Outbounds";
import ItemStats from "../components/DashBoard/ItemStats";

export default function Dashboard() {
  return (
    <Box>
      <DashBoardPageHeaderPage title="Welcome to your Dashboard..." />
      {/* <DashBoardItems /> */}
      <ItemStats />
      <BundleSummary />
      <CurrentDateDetails />
      <Outbounds />
    </Box>
  );
}
