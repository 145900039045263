import React, { useContext, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
  Paper,
  Grid,
} from "@mui/material";
import { get } from "../../services/apiMethods";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useFormik } from "formik";
import dayjs from "dayjs";

import FormDatePicker from "../../forms/FormDatePicker";
import FormDialogDashboard from "../../forms/FormDailogDashboard";
import WarehouseContext from "../../context/WarehouseContext";
import UserContext from "../../context/UserContext";

const Wrapper = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  padding: "10px",
  marginBottom: "20px",
}));

export default function DashBoardPageHeaderPage({ title = "" }) {
  const { user } = useContext(UserContext);
  const {
    selectedWarehouse,
    setSelectedWarehouse,
    allWarehouses,
    allOwnerCodes,
    selectedOwnerCode,
    setSelectedOwnerCode,
  } = useContext(WarehouseContext);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      formik.resetForm();
    }, 200);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(value);
    if (name === "warehouseName") {
      setSelectedWarehouse(value);
    } else {
      setSelectedOwnerCode(value);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const { data } = await get();
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      fromDate: dayjs(new Date()),
      toDate: dayjs(new Date()),
    },
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Wrapper elevation={1}>
        <Typography component="h1" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Stack direction="row" spacing={2}>
          {/* <Button
            size="small"
            fullWidth
            sx={{
              color: "gray",
              borderColor: "gray",
              "&:hover": {
                color: "gray",
                borderColor: "gray",
              },
            }}
            variant="outlined"
            onClick={handleOpen}
            startIcon={<CalendarMonthIcon />}
          >
            Filter Date
          </Button> */}

          {typeof user.role === "object" ? null : (
            <>
              <FormControl fullWidth size="small" sx={{ minWidth: "200px" }}>
                <InputLabel id="demo-simple-select-label">Warehouse</InputLabel>
                <Select
                  value={selectedWarehouse}
                  name="warehouseName"
                  size="small"
                  onChange={handleChange}
                  IconComponent={(props) => (
                    <KeyboardArrowDownIcon {...props} fontSize="medium" />
                  )}
                  label="Warehouse"
                >
                  {typeof user.role === "object" ? null : (
                    <MenuItem key={"all"} value={"all"}>
                      All
                    </MenuItem>
                  )}
                  {allWarehouses.map((w, i) => (
                    <MenuItem key={w._id} value={w._id}>
                      {w.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small" sx={{ minWidth: "200px" }}>
                <InputLabel id="demo-simple-select-label">Customer</InputLabel>
                <Select
                  value={selectedOwnerCode}
                  name="customerName"
                  size="small"
                  onChange={handleChange}
                  IconComponent={(props) => (
                    <KeyboardArrowDownIcon {...props} fontSize="medium" />
                  )}
                  label="Customer"
                >
                  {typeof user.role === "object" ? null : (
                    <MenuItem key={"all"} value={"all"}>
                      All
                    </MenuItem>
                  )}
                  {allOwnerCodes?.map((w, i) => (
                    <MenuItem key={w._id} value={w._id}>
                      {w.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </Stack>

        <FormDialogDashboard
          open={open}
          formik={formik}
          formTitle="Filter date"
          onClose={handleClose}
          submitButtonTitle="Apply"
        >
          <Grid rowSpacing={1} columnSpacing={2} container>
            <Grid xs={12} sm={6} md={6} item>
              <FormDatePicker
                formik={formik}
                label="From Date"
                name="fromDate"
              />
            </Grid>
            <Grid xs={12} sm={6} md={6} item>
              <FormDatePicker formik={formik} label="To Date" name="toDate" />
            </Grid>
          </Grid>
        </FormDialogDashboard>
      </Wrapper>
    </>
  );
}
