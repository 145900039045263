import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";

// custom imports
import themeData from "./data/themeData";
import ThemeModeContext from "./context/ThemeModeContext";
import Login from "./pages/Login";
import RootScreen from "./components/RootScreen";
import UserContext from "./context/UserContext";
import NavDrawer from "./components/NavDrawer";
import ForgotPassword from "./pages/ForgotPassword";
import { ToastContainer } from "react-toastify";
import PageNotFound from "./pages/PageNotFound";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import { get } from "./services/apiMethods";
import URLS from "./services/urlConstants";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [user, setUser] = useState(null);

  const getCurrentUser = async () => {
    try {
      const { data } = await get(URLS.auth.getCurrentUser);
      let user = data.result;
      setUser(user);
      if (user && user.role && user.role._id) {
        window.localStorage.setItem("role", JSON.stringify(user.role));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let isDark = window.localStorage.getItem("isDarkMode");

    setIsDarkMode(isDark === "true" ? true : false);
  }, [window.localStorage.getItem("isDarkMode")]);

  const restoreUser = async () => {
    try {
      let token = localStorage.getItem("access_token");
      if (token) {
        getCurrentUser();
        // let user = localStorage.getItem("current_inventory_user");
        // if (user) {
        //   setUser(JSON.parse(user));
        // }
        setIsReady(true);
      } else {
        setUser(null);
      }
    } catch (error) {
      console.log(error);
    }
    setIsReady(true);
  };

  useEffect(() => {
    restoreUser();
  }, []);

  const theme = createTheme({
    palette: isDarkMode ? themeData.darkPalette : themeData.lightPalette,
    shape: {
      borderRadius: themeData.shapeProperties.borderRadius,
    },
    typography: {
      allVariants: {
        color: isDarkMode ? "white" : "black",
        fontSize: "14px",
      },
    },

    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 100px rgba(0, 0, 0, 0) inset",
                boxShadow: "0 0 0 100px rgba(0, 0, 0, 0) inset",
                backgroundColor: "transparent !important",
                WebkitTextFillColor: "#fff !important", // Ensure text color is set
                transition: "background-color 5000s ease-in-out 0s",
              },
            },
          },
        },
      },

      MuiOutlinedInput: {
        // styleOverrides: {
        //   root: {
        //     "& .MuiOutlinedInput-notchedOutline": {
        //       border: `1px solid white`,
        //     },
        //     "&.Mui-focused": {
        //       "& .MuiOutlinedInput-notchedOutline": {
        //         border: `1px solid white`,
        //       },
        //     },
        //   },
        // },
      },

      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: isDarkMode ? "#4f4f4f" : "#e8eaed",
            },
          },
        },
      },
      MuiIcon: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            height: "40px",
          },
        },
      },

      MuiTableCell: {
        styleOverrides: {
          head: {
            color: "#fff",
          },
          root: {
            padding: "8px",
            height: "40px",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: "40px",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
          },
        },
      },

      MuiGrid: {
        styleOverrides: {
          root: {
            paddingTop: 0,
          },
        },
      },
    },
  });

  if (!isReady) return null;

  return (
    <ThemeModeContext.Provider value={{ isDarkMode, setIsDarkMode }}>
      <UserContext.Provider value={{ user, setUser, getCurrentUser }}>
        <ThemeProvider theme={theme}>
          {!user ? (
            <RootScreen>
              <Routes>
                <Route
                  path="/"
                  element={<PublicRoute component={<Login />} />}
                />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </RootScreen>
          ) : (
            <NavDrawer />
          )}
        </ThemeProvider>
        <ToastContainer />
      </UserContext.Provider>
    </ThemeModeContext.Provider>
  );
}

export default App;
