import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import dayjs from "dayjs";
import FormInput from "../../forms/FormInput";

import FormDatePicker from "../../forms/FormDatePicker";

import { get, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";

import ShowComponent from "../../components/ShowComponent";
import { hasAllValues } from "../../utils/utilities";
import CustomInput from "./../SecondaryForms/CustomInput";
import ThemeModeContext from "../../context/ThemeModeContext";
import Itemgraph from "./Itemgraph";
import WarehouseContext from "../../context/WarehouseContext";

const Title = styled(TableCell)(({ theme }) => ({
  borderBottom: 0,

  fontWeight: 700,
  padding: "6px",
}));

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "180px",
  color: "black",
  height: "20px",
}));

export default function DashBoarddShipmentPage() {
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const [formData, setFormData] = useState([]);
  const [updateStatuses, setUpdateStatuses] = useState([]);
  const [updating, setUpdating] = useState(-1);
  const [open, setOpen] = useState(false);
  const [selectedAWBNo, setSelectedAWBNo] = useState("");
  const [dataToEdit, setDataToEdit] = useState(null);
  const { isDarkMode } = useContext(ThemeModeContext);

  const handleOpen = (id, fd) => {
    setSelectedAWBNo(fd.awbNo);
    setDataToEdit(fd);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateOutbound = async (e, form) => {
    e.preventDefault();
    try {
      if (!hasAllValues(form)) return;
      let payload = {
        itemId: form._id,
        shippingRemarks: form.shippingRemarks,
        updateStatus: form.updateStatus,
      };
      setUpdating(formData.indexOf(form));
      const { data } = await put(
        URLS.outbound.update + "/" + form.entryId,
        payload
      );

      let entry = data.result;
      setUpdating(-1);
      getAllEntries();
      handleClose();
    } catch (error) {
      console.log(error);
      setUpdating(-1);
    }
  };

  const getUpdateStatuses = async () => {
    try {
      const { data } = await get(URLS.updateStatus.list);
      setUpdateStatuses(
        data.result.map((s) => ({ ...s, label: s.name, value: s._id }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    getUpdateStatuses();
  }, []);

  const handleFind = async (values) => {
    try {
      const { data } = await get(URLS.outbound.list, {
        params: {
          search: values,
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      let entries = data.result;
      if (entries && entries.length) {
        let forms = [];
        for (let entry of entries) {
          for (let item of entry.items) {
            let newForm = {
              entryId: entry._id,
              _id: item._id,
              courierPartner: item.courierPartner?.name,
              courierMode: item.courierMode?.name,
              awbNo: item.awbNo,
              pincode: item.pincode,
              updateStatus:
                item?.updateStatus[item.updateStatus.length - 1]?.status?._id ||
                "",

              shippingRemarks: item.shippingRemarks || "NA",
              date: new Date(entry.dispatchedAt).toLocaleDateString(),
              time: new Date(entry.dispatchedAt).toLocaleTimeString(),
            };
            forms.push(newForm);
          }
        }
        setFormData(forms);
      } else {
        setFormData([]);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getAllEntries = async () => {
    try {
      const { data } = await get(URLS.outbound.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      let entries = data.result;
      if (entries && entries.length) {
        let forms = [];
        for (let entry of entries) {
          for (let item of entry.items) {
            let newForm = {
              entryId: entry._id,
              _id: item._id,
              courierPartner: item.courierPartner?.name,
              courierMode: item.courierMode?.name,
              awbNo: item.awbNo,
              pincode: item.pincode,
              updateStatus:
                item?.updateStatus[item.updateStatus.length - 1]?.status?._id ||
                "",
              shippingRemarks: item.shippingRemarks || "NA",
              date: new Date(entry.dispatchedAt).toLocaleDateString(),
              time: new Date(entry.dispatchedAt).toLocaleTimeString(),
            };
            forms.push(newForm);
          }
        }
        setFormData(forms);
      } else {
        setFormData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllEntries();
  }, [selectedWarehouse, selectedOwnerCode]);

  const entryFormik = useFormik({
    initialValues: {
      name: "",
      fromDate: dayjs(new Date()),
      toDate: dayjs(new Date()),
    },
    onSubmit: handleFind,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataToEdit((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClearFilter = () => {
    entryFormik.resetForm();
    getAllEntries();
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }} style={{ display: "flex" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={4}
          style={{ height: "100%", maxHeight: 400 }}
        >
          <Paper
            elevation={1}
            sx={{
              padding: 2,
              textAlign: "center",
              marginTop: 2,
              height: "100%",
              overflow: "hidden",
            }}
          >
            <Itemgraph />
          </Paper>
        </Grid>

        {/* <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={8}
          sx={{
            height: "100%",
            maxHeight: 400,
            mt: { xs: "20px", sm: "20px", md: 0 },
          }}
        >
          <Grid spacing={2} container>
            <Grid xs={12} sm={6} md={4} lg={3} item>
              <FormInput
                type="text"
                formik={entryFormik}
                name="name"
                label="Courier Partner Name"
                required={true}
              />
            </Grid>

            <Grid xs={12} sm={6} md={4} lg={3} item>
              <FormDatePicker
                formik={entryFormik}
                label="From Date"
                name="fromDate"
              />
            </Grid>
            <Grid xs={12} sm={6} md={4} lg={3} item>
              <FormDatePicker
                formik={entryFormik}
                label="To Date"
                name="toDate"
              />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3} item>
              <Stack columnGap={1} direction="row">
                <Button
                  size="small"
                  onClick={entryFormik.handleSubmit}
                  variant="contained"
                  sx={{ mt: { lg: 2.5, md: 1, sm: 2.5 } }}
                >
                  Filter
                </Button>
                {entryFormik.values.name ? (
                  <Button
                    size="small"
                    onClick={handleClearFilter}
                    variant="outlined"
                    sx={{ mt: { lg: 2.5, md: 1, sm: 2.5 } }}
                  >
                    Clear Filter
                  </Button>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: { lg: 350, md: 300 },
              marginTop: { lg: "10px", md: "5px" },
              overflowY: "auto",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <Title
                    align="left"
                    colSpan={13}
                    sx={{ color: isDarkMode ? "white" : "black" }}
                  >
                    Recent Shipments
                  </Title>
                </TableRow>
                <TableRow
                  sx={{ backgroundColor: isDarkMode ? "white" : "#f5f5f5" }}
                >
                  <StyledTableCell align="center">
                    Courier Partner Name
                  </StyledTableCell>

                  <StyledTableCell align="center"> AWB No.</StyledTableCell>
                  <StyledTableCell align="center">
                    Pincode/Destination
                  </StyledTableCell>
                  <StyledTableCell align="center"> Status</StyledTableCell>

                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.map((fd, i) => (
                  <TableRow key={i}>
                    <StyledTableCell align="center">
                      <Typography>{fd.courierPartner}</Typography>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <Typography>{fd.awbNo}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.pincode}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>
                        {updateStatuses.find((s) => s.value === fd.updateStatus)
                          ?.label || ""}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <ShowComponent module={"Shipment"} action={"update"}>
                        <Button
                          size="small"
                          onClick={() => handleOpen(fd._id, fd)}
                          variant="contained"
                          sx={{ mt: 1 }}
                        >
                          Update
                        </Button>
                      </ShowComponent>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid> */}
      </Grid>

      {/* <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: 650,
          },
        }}
      >
        <DialogTitle>
          Update status for courier AWB No: {selectedAWBNo}
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} md={6} lg={6}>
            <CustomSelect
              name="updateStatus"
              value={dataToEdit ? dataToEdit.updateStatus : ""}
              label="Update Status"
              required={true}
              options={updateStatuses}
              onChange={(e) => handleChange(e, dataToEdit)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ mt: 0.5 }}>
            <CustomInput
              name="shippingRemarks"
              value={dataToEdit?.shippingRemarks}
              label="Shipping Remarks"
              required={true}
              onChange={(e) => handleChange(e, dataToEdit)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            size="small"
            color="error"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            size="small"
            onClick={(e) => handleUpdateOutbound(e, dataToEdit)}
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
