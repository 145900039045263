import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Avatar, Collapse, Stack, Switch } from "@mui/material";
import { Link } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import ThemeModeContext from "../context/ThemeModeContext";
import menu from "../data/menu";
import Dashboard from "../pages/Dashboard";
import Settings from "../pages/Settings";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LOGO from "../assets/images/demoLogo.png";
import RolesPermission from "../pages/RolesPermission";
import New from "../pages/Inbound/New";
import Used from "../pages/Inbound/Used";
import Faulty from "../pages/Inbound/Faulty";
import Warehouse from "../pages/Master/Warehouse";
import StockType from "../pages/Master/StockType";
import ItemCategory from "../pages/Master/ItemCategory";
import StorageCode from "../pages/Master/StorageCode";
import PalletCode from "../pages/Master/PalletCode";
import DamageCode from "../pages/Master/DamageCode";
import WorkflowCode from "../pages/Master/WorkflowCode";
import ActivityCode from "../pages/Master/ActivityCode";
import Family from "../pages/Master/Family";
import SubFamily from "../pages/Master/SubFamily";
import Add from "../pages/Bundling/Add";
import Unbundle from "../pages/Bundling/Unbundle";
import Employee from "../pages/Master/Employee";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/UserContext";
import PageNotFound from "../pages/PageNotFound";
import BundleType from "../pages/Bundling/BundleType";
import MasterDataContext from "../context/MasterDataContext";
import CourierMode from "../pages/Master/CourierMode";
import CourierPartners from "../pages/Master/CourierPartners";
import UpdateStatus from "../pages/Master/UpdateStatus";
import Shipment from "../pages/Shipment";
import PickList from "../pages/Outbound/PickList";
import ProcessPickList from "../pages/Outbound/ProcessPickList";
import Outbound from "../pages/Outbound/Outbound";
import Inventory from "../pages/Inventory";
import OwnerGroup from "../pages/Master/OwnerGroup";
import OwnerCode from "../pages/Master/OwnerCode";
import GradingScale from "../pages/Master/GradingScale";
import Reports from "../pages/Reports";
import Brand from "../pages/Master/Brand";
import ConditionCode from "../pages/Master/ConditionCode";
import Packaging from "../pages/Master/Packaging";
import UpdateInventory from "./Inventory/UpdateInventory";
import UpdateNewStock from "./Inventory/UpdateNewStock";
import UpdateUsedStock from "./Inventory/UpdateUsedStock";
import UpdateFaultyStock from "./Inventory/UpdateFaultyStock";
import UpdateSavedStock from "./Inventory/UpdateSavedStock";
import UpdateBundledStock from "./Inventory/UpdateBundledStock";
import WarehouseContext from "../context/WarehouseContext";
import ItemHistory from "../pages/ItemHistory";

// services
import { get } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import { jwtDecode } from "jwt-decode";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function NavDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const { setUser, user } = React.useContext(UserContext);
  const [sideMenuData, setSideMenuData] = React.useState([]);
  const navigate = useNavigate();

  const { isDarkMode, setIsDarkMode } = React.useContext(ThemeModeContext);
  const [selecteSubMenu, setSelectedSubMenu] = React.useState("");
  const [selectedMenu, setSelectedMenu] = React.useState(null);
  const { SIDE_MENU_DATA } = menu;

  const [allWarehouses, setAllWarehouses] = React.useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = React.useState("all");

  const [allOwnerCodes, setAllOwnerCodes] = React.useState([]);
  const [selectedOwnerCode, setSelectedOwnerCode] = React.useState("all");

  const getData = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);
      setAllWarehouses(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const getOwnerCodes = async () => {
    try {
      const { data } = await get(URLS.ownerCode.list);
      setAllOwnerCodes(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getData();
    getOwnerCodes();
  }, []);

  React.useEffect(() => {
    const token = window.localStorage.getItem("access_token");
    if (token) {
      let decodedToken = jwtDecode(token);
      let warehouseId = decodedToken.data.warehouseId;
      let employee = window.localStorage.getItem("current_inventory_user");
      let employeeData = JSON.parse(employee);

      if (warehouseId && employeeData) {
        setSelectedWarehouse(warehouseId);

        setAllOwnerCodes(employeeData.customerGroup);

        setSelectedOwnerCode(employeeData.customerGroup[0]?._id);
      } else {
        if (allWarehouses.length > 0) {
          setSelectedOwnerCode(allOwnerCodes[0]?._id);
          setSelectedWarehouse(allWarehouses[0]?._id);
        }
      }
    }
  }, [
    allWarehouses,
    allOwnerCodes,
    window.localStorage.getItem("current_inventory_user"),
  ]);

  React.useEffect(() => {
    const roleDetails = window.localStorage.getItem("role");
    if (!roleDetails) {
      setSideMenuData(SIDE_MENU_DATA);
    } else {
      let role = JSON.parse(roleDetails);
      let permissions = role.permissions;
      let newSideMenuData = [];
      for (let menuData of SIDE_MENU_DATA) {
        if (menuData.name === "Dashboard") {
          newSideMenuData.push(menuData);
        }
        if (menuData.subMenus.length) {
          let newMenu = { ...menuData, subMenus: [] };

          for (let ren of menuData.renderName) {
            if (
              permissions
                ?.filter((p) => p.module.toLowerCase() === ren)[0]
                ?.permissions?.includes("view")
            ) {
              newMenu.subMenus = [
                ...new Set([
                  ...newMenu.subMenus,
                  ...menuData.subMenus.filter((s) => s.renderName === ren),
                ]),
              ];
            }
          }

          if (newMenu.subMenus.length) {
            newSideMenuData.push(newMenu);
          }
        } else {
          if (
            permissions
              ?.filter(
                (p) => p.module.toLowerCase() === menuData.renderName[0]
              )[0]
              ?.permissions?.includes("view")
          ) {
            newSideMenuData.push(menuData);
          }
        }
      }

      setSideMenuData(newSideMenuData);
    }
  }, [user]);

  // const []

  // on load if any selected sub-menu was there then open
  React.useEffect(() => {
    const openPreviousMenu = () => {
      let path = window.location.pathname;
      let menuIndex = null;

      for (let menu of SIDE_MENU_DATA) {
        if (menu.subMenus.length) {
          let pathNames = menu.subMenus.map((s) => s.path);
          if (pathNames.includes(path)) {
            menuIndex = SIDE_MENU_DATA.indexOf(menu);
          }
        }
      }

      if (menuIndex) {
        setSelectedMenu(menuIndex);
        setOpen(true);
      }
    };

    openPreviousMenu();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleModeChange = () => {
    setIsDarkMode(!isDarkMode);
    window.localStorage.setItem("isDarkMode", !isDarkMode);
  };

  const handleToggleMenuOpen = (index) => {
    if (selectedMenu === index) {
      return setSelectedMenu(null);
    }
    setSelectedMenu(index);
  };

  const handleLogout = () => {
    window.localStorage.removeItem("inventory_current_user");
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("role");
    setUser(null);
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" color="white">
            Inventory
          </Typography>
          {/* <Box
            sx={{
              display: "flex",
              width: "100%",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{ display: "flex", alignItems: "center" }}
              spacing={1}
              direction="row"
            >
              <IconButton>
                <CircleNotificationsIcon fontSize="large" color="secondary" />
              </IconButton>
              <Avatar />
            </Stack>
          </Box> */}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ justifyContent: "space-between" }}>
          <Box></Box>
          <img
            style={{ height: "60px", width: "150px", objectFit: "contain" }}
            src={LOGO}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon color="primary" />
            ) : (
              <ChevronLeftIcon color="primary" />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {sideMenuData.map((m, index) =>
            !m.subMenus.length ? (
              <ListItem key={m.path} disablePadding>
                <Link
                  to={m.path}
                  style={{ textDecoration: "none", display: "flex", flex: 1 }}
                >
                  <ListItemButton
                    onClick={() => setSelectedMenu(index)}
                    selected={
                      selectedMenu === index ||
                      window.location.pathname === m.path
                    }
                  >
                    <ListItemIcon>{m.icon}</ListItemIcon>
                    <ListItemText primary={m.name} />
                  </ListItemButton>
                </Link>
              </ListItem>
            ) : (
              <div key={m.path + m.name}>
                <ListItem key={m.path} sx={{ padding: 0 }}>
                  <ListItemButton
                    selected={
                      selectedMenu === index ||
                      window.location.pathname === m.path
                    }
                    onClick={() => handleToggleMenuOpen(index)}
                  >
                    <ListItemIcon>{m.icon}</ListItemIcon>
                    <ListItemText primary={m.name} />

                    {selectedMenu === index ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </ListItem>
                <Collapse
                  timeout="auto"
                  unmountOnExit
                  in={selectedMenu === index}
                  sx={{ width: "100%" }}
                >
                  <List>
                    {m.subMenus.map((sub) => (
                      <ListItem sx={{ width: "100%" }} key={sub.name}>
                        <Link
                          to={sub.path}
                          style={{
                            textDecoration: "none",
                            display: "flex",
                            flex: 1,
                          }}
                        >
                          <ListItemButton
                            onClick={() => setSelectedSubMenu(sub.path)}
                            selected={
                              selecteSubMenu === sub.path ||
                              window.location.pathname === sub.path
                            }
                          >
                            <ListItemIcon>{sub.icon}</ListItemIcon>
                            <ListItemText primary={sub.name} />
                          </ListItemButton>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </div>
            )
          )}
        </List>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton sx={{ paddingLeft: 0 }}>
            <Switch
              sx={{ marginRight: "15px" }}
              checked={isDarkMode}
              onChange={handleModeChange}
            />
            <ListItemText primary={isDarkMode ? "Dark Mode" : "Light Mode"} />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ marginBottom: "20px" }} />
        <DrawerFooter>
          <List sx={{ width: "100%" }}>
            <ListItem disablePadding>
              <Link
                to={"/settings"}
                style={{ textDecoration: "none", display: "flex", flex: 1 }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Settings"} />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          </List>
        </DrawerFooter>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          height: "100vh",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <DrawerHeader />
        <WarehouseContext.Provider
          value={{
            selectedWarehouse,
            setSelectedWarehouse,
            allWarehouses,
            allOwnerCodes,
            selectedOwnerCode,
            setSelectedOwnerCode,
          }}
        >
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/role-permissions" element={<RolesPermission />} />
            {/* inbound */}
            <Route path="/inbound-process/item/new" element={<New />} />
            <Route path="/inbound-process/item/used" element={<Used />} />
            <Route path="/inbound-process/item/faulty" element={<Faulty />} />

            <Route path="/inventory" element={<Inventory />} />
            <Route path="/rma" element={<ItemHistory />} />

            {/* master */}
            <Route path="/master/warehouse" element={<Warehouse />} />
            <Route path="/master/stock-type" element={<StockType />} />
            <Route path="/master/item-category" element={<ItemCategory />} />
            <Route path="/master/codes/storage" element={<StorageCode />} />
            <Route path="/master/codes/pallet" element={<PalletCode />} />
            <Route path="/master/codes/damage" element={<DamageCode />} />
            <Route path="/master/codes/workflow" element={<WorkflowCode />} />
            <Route path="/master/codes/activity" element={<ActivityCode />} />
            <Route path="/master/family" element={<Family />} />
            <Route path="/master/sub-family" element={<SubFamily />} />
            <Route path="/master/employee" element={<Employee />} />
            <Route path="/master/courier-mode" element={<CourierMode />} />
            <Route path="/master/owner-group" element={<OwnerGroup />} />
            <Route path="/master/owner-code" element={<OwnerCode />} />
            <Route path="/master/grading-scale" element={<GradingScale />} />
            <Route path="/master/brand" element={<Brand />} />
            <Route path="/master/codes/condition" element={<ConditionCode />} />
            <Route path="/master/packaging" element={<Packaging />} />

            <Route
              path="/master/courier-partners"
              element={<CourierPartners />}
            />
            <Route path="/master/update-status" element={<UpdateStatus />} />

            {/* Bundling */}
            <Route path="/bundling/bundle-type" element={<BundleType />} />

            <Route path="/bundling/add" element={<Add />} />
            <Route path="/bundling/un-bundle" element={<Unbundle />} />

            {/* Outbound */}

            <Route path="/outbound/pick-list" element={<PickList />} />
            <Route
              path="/outbound/process-pick-list"
              element={<ProcessPickList />}
            />
            <Route path="/outbound" element={<Outbound />} />

            <Route path="/shipment" element={<Shipment />} />

            <Route path="/reports" element={<Reports />} />

            <Route path="/settings" element={<Settings />} />
            <Route path="/inventory/update" element={<UpdateInventory />} />

            <Route path="*" element={<PageNotFound />} />
            <Route path="/edit/new/stock/:id" element={<UpdateNewStock />} />
            <Route path="/edit/used/stock/:id" element={<UpdateUsedStock />} />
            <Route
              path="/edit/faulty/stock/:id"
              element={<UpdateFaultyStock />}
            />

            <Route
              path="/edit/saved/stock/:id"
              element={<UpdateSavedStock />}
            />
            <Route path="/edit/bundle/stock" element={<UpdateBundledStock />} />
          </Routes>
        </WarehouseContext.Provider>
      </Box>
    </Box>
  );
}
