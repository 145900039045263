import { Grid, Paper, Tooltip, Typography, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { get } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import Clock from "react-live-clock";
import WarehouseContext from "../../context/WarehouseContext";
const DataHeader = styled(Typography)(({}) => ({
  fontWeight: "bold",
  fontSize: "18px",
}));

const DataHeaderMain = styled(Typography)(({}) => ({
  color: "gray",
  fontSize: "16px",
}));

const DataContainer = styled(Paper)(({}) => ({
  textAlign: "center",
  padding: "20px",
  boxShadow:
    "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
}));

function sumValues(stock) {
  let sum = 0;

  for (const category in stock) {
    stock[category].forEach((item) => {
      sum += item.count;
    });
  }

  return sum;
}

export default function DashBoardItems() {
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const [newStock, setNewStock] = useState(0);
  const [usedStock, setUsedStock] = useState(0);
  const [faultyStock, setFaultyStock] = useState(0);
  const [savedStock, setSavedStock] = useState(0);
  const [subFamily, setSubFamily] = useState([]);

  const getSubFamily = async () => {
    try {
      const { data } = await get(URLS.subFamily.list);
      setSubFamily(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubFamily();
  }, []);

  const getFaultyItemsList = async () => {
    try {
      const { data } = await get(URLS.items.list, {
        params: {
          search: { inStock: false, inBundle: false, isSaved: true },
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });

      let faultyItemData = {};
      faultyItemData["Assets"] = [];
      faultyItemData["Accessories"] = [];

      for (let item of data.result.filter(
        (i) => i.itemCategory?.name?.toLowerCase() === "faulty"
      )) {
        if (
          faultyItemData[item.subFamily.category].find(
            (i) => i.subFamilyId === item.subFamily._id
          )
        ) {
          let previousData = faultyItemData[item.subFamily.category].find(
            (id) => id.subFamilyId == item.subFamily._id
          );
          if (item.itemCategory?.name?.toLowerCase() === "faulty") {
            previousData.count += 1;
          } else {
          }

          faultyItemData[item.subFamily.category] = faultyItemData[
            item.subFamily.category
          ].map((i) =>
            i.subFamilyId === previousData.subFamilyId ? previousData : i
          );
        } else {
          let newEntry = {
            subFamilyId: item.subFamily._id,
            subFamilyName: item.subFamily.name,
            count: item.itemCategory.name.toLowerCase() === "faulty" ? 1 : 0,
          };

          faultyItemData[item.subFamily.category].push(newEntry);
        }
      }
      setFaultyStock(sumValues(faultyItemData));
    } catch (error) {}
  };

  const getItemsList = async () => {
    try {
      const { data } = await get(URLS.items.list, {
        params: {
          search: { inStock: true, inBundle: false },
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });

      let newItemData = {};
      newItemData["Assets"] = [];
      newItemData["Accessories"] = [];
      newItemData["Spares"] = [];

      for (let item of data.result) {
        if (
          newItemData[item.subFamily.category]?.find(
            (i) => i.subFamilyId === item.subFamily._id
          )
        ) {
          let previousData = newItemData[item.subFamily.category]?.find(
            (id) => id.subFamilyId == item.subFamily._id
          );
          if (item.itemCategory?.name?.toLowerCase() === "new") {
            previousData.count += 1;
          } else {
          }

          newItemData[item.subFamily.category] = newItemData[
            item.subFamily.category
          ]?.map((i) =>
            i.subFamilyId === previousData.subFamilyId ? previousData : i
          );
        } else {
          let newEntry = {
            subFamilyId: item.subFamily._id,
            subFamilyName: item.subFamily.name,
            count: item.itemCategory.name.toLowerCase() === "new" ? 1 : 0,
          };

          newItemData[item.subFamily.category]?.push(newEntry);
        }
      }
      setNewStock(sumValues(newItemData));

      let usedItemData = {};
      usedItemData["Assets"] = [];
      usedItemData["Accessories"] = [];

      for (let item of data.result) {
        if (
          usedItemData[item.subFamily.category]?.find(
            (i) => i.subFamilyId === item.subFamily._id
          )
        ) {
          let previousData = usedItemData[item.subFamily.category]?.find(
            (id) => id.subFamilyId == item.subFamily._id
          );
          if (item.itemCategory?.name?.toLowerCase() === "used") {
            previousData.count += 1;
          } else {
          }

          usedItemData[item.subFamily.category] = usedItemData[
            item.subFamily.category
          ]?.map((i) =>
            i.subFamilyId === previousData.subFamilyId ? previousData : i
          );
        } else {
          let newEntry = {
            subFamilyId: item.subFamily._id,
            subFamilyName: item.subFamily.name,
            count: item.itemCategory.name.toLowerCase() === "used" ? 1 : 0,
          };

          usedItemData[item.subFamily.category]?.push(newEntry);
        }
      }
      setUsedStock(sumValues(usedItemData));
    } catch (error) {}
  };

  const getSavedStock = async () => {
    try {
      const { data } = await get(URLS.items.listSavedStock, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      let items = data.result;

      if (!items.length) return setSavedStock(0);

      let savedItemData = {};
      savedItemData["Assets"] = [];
      savedItemData["Accessories"] = [];
      savedItemData["Spares"] = [];

      for (let item of items) {
        if (
          savedItemData[
            subFamily.find((s) => s._id == item.subFamily._id)?.category
          ]?.find((i) => i.subFamilyId === item.subFamily._id)
        ) {
          let previousData = savedItemData[
            subFamily.find((s) => s._id == item.subFamily._id)?.category
          ]?.find((id) => id.subFamilyId == item.subFamily._id);
          previousData.count += 1;

          savedItemData[
            subFamily.find((s) => s._id == item.subFamily._id)?.category
          ] = savedItemData[
            subFamily.find((s) => s._id == item.subFamily._id)?.category
          ]?.map((i) =>
            i.subFamilyId === previousData.subFamilyId ? previousData : i
          );
        } else {
          let newEntry = {
            subFamilyId: item.subFamily._id,
            subFamilyName: subFamily.find((s) => s._id == item.subFamily._id)
              ?.name,
            count: 1,
          };

          savedItemData[
            subFamily.find((s) => s._id == item.subFamily._id)?.category
          ]?.push(newEntry);
        }

        setSavedStock(sumValues(savedItemData) || 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getItemsList();
    getSavedStock();
    getFaultyItemsList();
  }, [selectedWarehouse, window.location.pathname, selectedOwnerCode]);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <DataContainer>
            <DataHeader>{new Date(Date.now()).toLocaleDateString()}</DataHeader>
            <Clock
              style={{ fontWeight: "bold", fontSize: "18px" }}
              format={"HH:mm:ss"}
              ticking={true}
              timezone={"Asia/Kolkata"}
            />
          </DataContainer>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <DataContainer>
            <DataHeaderMain>New Items</DataHeaderMain>
            <DataHeader> {newStock}</DataHeader>
          </DataContainer>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <DataContainer>
            <DataHeaderMain>Used Items</DataHeaderMain>
            <DataHeader>{usedStock}</DataHeader>
          </DataContainer>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <DataContainer>
            <DataHeaderMain>Faulty Items</DataHeaderMain>
            <DataHeader>{faultyStock}</DataHeader>
          </DataContainer>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <DataContainer>
            <DataHeaderMain>Saved Items</DataHeaderMain>
            <DataHeader>{savedStock}</DataHeader>
          </DataContainer>
        </Grid>
      </Grid>
    </>
  );
}
