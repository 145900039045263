import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  styled,
} from "@mui/material";

import { useFormik } from "formik";

// img
import ManBoxes from "../assets/images/login.Img/ManBoxes.png";
import logo from "../assets/images/whiteLogo.png";
import ThemeModeContext from "../context/ThemeModeContext";
import UserContext from "../context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import FormInput from "../forms/FormInput";
import { post } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import { jwtDecode } from "jwt-decode";
import Login_Background from "../assets/images/login_background.png";
import LoginInput from "../components/LoginInput";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";

const LeftComponent = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const RightComponent = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Item = styled(Box)(() => ({
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
}));

const MuiForm = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const MuiButton = styled(Button)({
  borderRadius: "25px",
  marginTop: "40px",
  maxWidth: "350px",
  height: "50px",
  backgroundColor: "white",
  color: "black",
  ":hover": {
    backgroundColor: "white",
  },
});

export default function Login() {
  const { setUser } = useContext(UserContext);
  const { isDarkMode } = useContext(ThemeModeContext);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async (values) => {
    try {
      const { data } = await post(URLS.auth.login, values);
      const decodedToken = jwtDecode(data.result.access_token);

      setUser(data.result.user);
      window.localStorage.setItem(
        "current_inventory_user",
        JSON.stringify(data.result.user)
      );

      if (decodedToken.data.userType === "employee") {
        const role = data.result.user.role;
        window.localStorage.setItem("role", JSON.stringify(role));
      }

      window.localStorage.setItem("access_token", data.result.access_token);
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    onSubmit: async (values) => {
      handleLogin(values);
    },
    validateOnBlur: false,
  });

  return (
    <>
      <Grid container>
        <RightComponent
          item
          xs={12}
          // md={6}
          sx={{
            height: "100vh",
            backgroundImage: "url('/login_background.png')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.3)", // Overlay color
              borderRadius: "inherit",
              pointerEvents: "none", // Allows clicks to pass through the overlay
            },
          }}
        >
          <Item
            elevation={2}
            sx={{
              width: "100%",
              maxWidth: "450px",
              marginRight: "10px",
              marginLeft: "10px",
              justifyContent: "center",
            }}
          >
            <MuiForm
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{
                maxWidth: { xs: "100%", sm: 450, md: 460, lg: 650 },
                width: "100%",
                padding: "70px 20px",
                backgroundColor: "rgba(255,255,255,0.2)",
                borderRadius: "16px",
                boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
                backdropFilter: "blur(10.8px)",
                WebkitBackdropFilter: "blur(10.8px)",
                border: "1px solid rgba(255,255,255,0.45)",
              }}
            >
              <img
                src={logo}
                alt="loading.."
                width={320}
                style={{ marginBottom: "20px", objectFit: "contain" }}
              />

              <LoginInput
                formik={formik}
                name="userName"
                label="Username"
                type="text"
                required={true}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        disabled
                      >
                        <PersonRoundedIcon sx={{ color: "white" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LoginInput
                formik={formik}
                name="password"
                label="Password"
                required={true}
                type={showPassword ? "text" : "password"}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <Visibility sx={{ color: "white" }} />
                        ) : (
                          <VisibilityOff sx={{ color: "white" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* <Link to={"/forgot-password"}>
                <Typography sx={{ textDecoration: "none", mt: 1 }}>
                  Forgot Password?
                </Typography>
              </Link> */}
              <MuiButton
                fullWidth
                size="small"
                type="submit"
                variant="contained"
                disableElevation
              >
                Login
              </MuiButton>
            </MuiForm>
          </Item>
        </RightComponent>
      </Grid>
    </>
  );
}
