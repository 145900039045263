const darkPalette = {
  primary: {
    main: "#1976D2", // Royal Blue
  },
  secondary: {
    main: "#FFA000", // Amber
  },
  mode: "dark",
};

const lightPalette = {
  primary: {
    main: "#2196F3", // Dodger Blue
  },
  secondary: {
    main: "#FFC107", // Amber
  },
  mode: "light",
};

const shapeProperties = {
  borderRadius: 5,
};

export default {
  lightPalette,
  darkPalette,
  shapeProperties,
};
